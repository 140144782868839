<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="teacherMagistracyPrerequisite_form.disciplines.length">
        <h4 class="mt-4 text-center">Список дисциплин (пререквизиты)</h4>


        <!-- Table Start -->
        <DataTable class="mt-4" :value="teacherMagistracyPrerequisite_form.disciplines" :paginator="true" :rows="50"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[50, 100, 200]"
                   filterDisplay="menu"
                   :globalFilterFields="['education_discipline_name']"
                   v-model:filters="filters"
                   currentPageReportTemplate="Показано {last} из {totalRecords}"
                   stripedRows responsiveLayout="scroll">

          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск дисциплины"/>
              </span>
            </div>
          </template>


<!--          <Column field="barcode" header="Баркод"></Column>-->

<!--          <Column header="ФИО">-->
<!--            <template #body="{data}">-->
<!--              <div v-if="data.middle_name == null">-->
<!--                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                {{ capitalizeFirstLetter(data.last_name) }} {{ capitalizeFirstLetter(data.first_name) }}-->
<!--                {{ capitalizeFirstLetter(data.middle_name) }}-->
<!--              </div>-->

<!--            </template>-->
<!--          </Column>-->
<!--          <Column field="student_groups_name" header="Группа"></Column>-->
          <Column header="№" :style="{width: '60px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column field="education_discipline_name" header="Дисциплина"></Column>

          <Column header="Студенты">
            <template #body="{data}">
              <ul>
                <li v-for="(item, index) in data.students"
                    :key="index">
                  {{item.barcode}} - {{item.last_name}} {{item.first_name}} - <strong>{{item.score}}</strong>
                </li>
              </ul>

            </template>
          </Column>
          <Column header="Оценка">
            <template #body="{data}">
              <Button label="Оценка" @click="openAddScoreDialog(data.students)"/>
            </template>
          </Column>


        </DataTable>
        <!-- Table End -->

      </div>
      <h5 v-else class="text-center mt-4">Студенты не прикреплены</h5>

    </div>

    <Dialog header="Поставить оценку" :visible="addScoreDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :closable="false">


      <div class="row mt-4" v-for="(item, index) in selected_students"
           :key="index">





          <div class="col-md-6">
            <p>{{item.barcode}} - {{item.last_name}} {{item.first_name}}</p>
          </div>
          <div class="col-md-6">
            <input class="form-control" type="number" placeholder="Оценка"
                   :value="item.score"
                   @change="changeScore(item.prerequisites_ratings_id, $event)">
          </div>



      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddScoreDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" :loading="btnSaveDisabled" @click="submitScore"/>
      </template>
    </Dialog>

  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "TeacherMagistracyPrerequisiteGrades",
  data() {
    return {
      loading: true,
      addScoreDisplay: false,
      btnSaveDisabled: false,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_discipline_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },

      },
      disciplines: [
          {
            id: 1,
            discipline_name: 'Маркетинг',
            discipline_id: 17,
            credit: 5,
            students: [
                {
                  id: 1,
                  barcode: 1811147,
                  student_id: 2656,
                  last_name: 'Иванов',
                  first_name: 'Иван',
                  middle_name: 'Иванович',
                  student_group: 'Группа',
                  practice_grade: 0,
                  formation_education_program: 56,
                  semester: 8,
                  credit: 12
                },
                {
                  id: 2,
                  barcode: 1910595,
                  student_id: 2845,
                  last_name: 'Артемов',
                  first_name: 'Артем',
                  middle_name: 'Артемович',
                  student_group: 'Группа 12',
                  practice_grade: 0,
                  formation_education_program: 57,
                  semester: 6,
                  credit: 8
                }
            ]
          },
      ],
      // students: [
      //   {
      //     id: 1,
      //     barcode: 1811147,
      //     student_id: 2656,
      //     last_name: 'Иванов',
      //     first_name: 'Иван',
      //     middle_name: 'Иванович',
      //     student_group: 'Группа',
      //     practice_grade: 0,
      //     formation_education_program: 56,
      //     semester: 8,
      //     credit: 12
      //   },
      //   {
      //     id: 2,
      //     barcode: 1910595,
      //     student_id: 2845,
      //     last_name: 'Артемов',
      //     first_name: 'Артем',
      //     middle_name: 'Артемович',
      //     student_group: 'Группа 12',
      //     practice_grade: 0,
      //     formation_education_program: 57,
      //     semester: 6,
      //     credit: 8
      //   }
      // ],
      selected_students: [],
      selected_discipline_id: null,
      //selected_discipline_credit: null,
      //score: null,
      prerequisiteRatings: []
    }
  },
  computed: {
    ...mapState('teacherMagistracyPrerequisite', ['teacherMagistracyPrerequisite_form']),
  },

  methods: {
    ...mapActions('teacherMagistracyPrerequisite', ['GET_MAGISTRACY_PREREQUISITE_DISCIPLINES', 'POST_UPDATE_MAGISTRACY_PREREQUISITE_RATINGS']),

    openAddScoreDialog(students) {
      this.selected_students = students
      //this.selected_discipline_id = discipline_id
      //this.selected_discipline_credit = credit
      // const student = this.teacherMagistracyPrerequisite_form.students.find(i => i.student_id === student_id)
      //
      // if (student) {
      //   this.studentName = student?.last_name + ' ' + student?.first_name
      // }
      this.addScoreDisplay = true
    },
    closeAddScoreDialog() {
      this.addScoreDisplay = false
      this.selected_students = []
      //this.selected_discipline_id = null
      //this.selected_discipline_credit = null
      //this.score = null
    },
    changeScore(prerequisites_ratings_id, e) {
      let score = e.target.value

      if (this.prerequisiteRatings.find(i=>i.id == prerequisites_ratings_id)) {
        this.prerequisiteRatings.find(i=>i.id == prerequisites_ratings_id).score = score
      }
      else {
        this.prerequisiteRatings.push({
          score: score,
          //student_id: student_id,
          //discipline_id: this.selected_discipline_id,
          id: prerequisites_ratings_id,
          //credit: this.selected_discipline_credit
        })
      }


      console.log(this.prerequisiteRatings, 'this.prerequisiteRatings')
    },

    async submitScore() {
      this.addScoreDisplay = false
      this.btnSaveDisabled = true

      //let ratingsData = this.teacherMagistracyPrerequisite_form.students.find(i=>i.student_id == this.student_id)

      let form = this.prerequisiteRatings
      // form['student_id'] = this.student_id
      // form['score'] = this.score
      // form['formation_education_program'] = ratingsData.formation_id
      // form['semester'] = ratingsData.semester
      // form['credit'] = ratingsData.credit
      //let form = []
      // form.push({
      //   student_id: this.student_id,
      //   score: this.score,
      //   formation_education_program: ratingsData.formation_id,
      //   semester: ratingsData.semester,
      //   credit: ratingsData.credit,
      // })
      console.log(form, 'submit post form')


      const postRes = await this.POST_UPDATE_MAGISTRACY_PREREQUISITE_RATINGS(form)
      if (postRes) {
        await this.GET_MAGISTRACY_PREREQUISITE_DISCIPLINES()
        this.$message({title: 'Успешно сохранено'})
        this.prerequisiteRatings = []
        //this.score = null
        this.selected_students = []
        this.selected_discipline_id = null
        //this.selected_discipline_credit = null
      }



      this.btnSaveDisabled = false


    },

  },
  async mounted() {

    await this.GET_MAGISTRACY_PREREQUISITE_DISCIPLINES()
    this.loading = false
  }

}
</script>

<style scoped>

</style>